.root {
   display: flex;
   margin-top: 16px;
   background: white;
   border-radius: 8px;
   height: 200px;
   width: 100%;
   transition: height 200ms ease-in-out;
   box-shadow: 0px 0px 1px rgba(34, 12, 74, 0.12),0px 1px 1px rgba(34, 12, 74, 0.12),0px 4px 6px rgba(34, 12, 74, 0.08),0px 8px 12px rgba(34, 12, 74, 0.06),0px 16px 24px rgba(34, 12, 74, 0.04);
  &.hide {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 56px;
  }
 };
 .hide {};
 .rocket {
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   transition: height 1s;
   &.hide {
     left: 0;
     top: 0;
     width: 100%;
     height: 100%;
   }
 };
  .header {
    padding-bottom: 8px;
    font-size: 24px !important;
    font-weight: 700 !important;
    color: #220C4A !important;
    left: 0;
    transition: font-size 200ms ease-in-out;
    &.hide {
      font-size: 16px !important;
      align-self: flex-start;
      padding-bottom: 0px;
    }
  };
 .content {
   margin: auto;
   display: flex;
   flex-direction: column;
   width: 500px;
   left: 0;
   &.hide {
     width: 100%;
     flex-direction: row;
     align-items: center;
     justify-content: space-between;
     position: relative;
   }
 };
  .text {
    font-size: 14px !important;
    font-weight: 400 !important;
    max-width: 500px;
    color: #220C4A !important;
    margin-bottom: 8px !important;
  };
 buttons {
   display: flex;
   align-self: flex-end;
 };
 .button {
   border-radius: 4px;
   height: 24px !important;
   padding: 16px !important;
   font-size: 12px !important;
   background-color: #2CE27E !important;
   color: #220C4A !important;
   margin-right: 8px;
 };
  .a:hover, a:visited, a:link, a:active {
  text-decoration: none !important;
  }
.buttonWhite {
  border-radius: 4px;
  height: 24px !important;
  padding: 16px !important;
  font-size: 12px !important;
  background-color: white !important;
  color: #220C4A !important;
  margin-right: 8px;
  &.hide {
    background-color: #2CE27E !important;
    align-self: flex-end;
  }
};

