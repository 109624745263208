.menuButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background-color: white !important;
  border-color: rgba(32, 16, 71, .1) !important;
  box-shadow: none !important;
  padding: 0;

  &::after {
    display: none !important;
  }

  .dots {
    color: var(--purple) !important;
  }

  &.open {
    background-color: var(--purple) !important;

    .dots {
      color: white !important;
    }
  }
}

.menu {
  transition: none !important;
  overflow: hidden;
  box-shadow: none;
  border-color: rgba(32, 16, 71, .1) !important;
  min-height: calc(16px + (8 * 38px));
  max-height: calc(16px + (8 * 38px));
}
