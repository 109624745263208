.uniqueRef {
  color: #201047;
  font-family: ClanPro-WideBold, 'sans-serif';
}

.tooltip {
  color: #fff;
  font-family: ClanPro-WideNews, 'sans-serif';
  font-size: 10px;
}
