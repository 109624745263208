.MuiPickersCalendarHeader-switchHeader p {
  font-family: ClanPro-WideNews !important;
}

.MuiPickersCalendarHeader-daysHeader span {
  font-family: ClanPro-WideNews !important;
}

span.MuiIconButton-label p {
  font-family: ClanPro-WideNews !important;
}

.MuiInputAdornment-positionEnd {
  margin-left: 8px;
  margin-right: -10px !important;
}
