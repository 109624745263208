.table {
  color: #201047 !important;
  font-family: 'ClanPro-WideNews', sans-serif;
  font-size: 12px !important;
}

.table_header {
  text-transform: none !important;
  margin-right: 21px !important;
}

.table_row {
  cursor: default;
  padding: 5px 0 3px 5px;
  margin-right: 12px !important;
}
