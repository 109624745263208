.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 220px;
  margin: 0 16px;
  justify-content: center;
}

.date_picker {
  label, div {
    font-family: ClanPro-WideNews, 'sans-serif';
  }
  div {
    height: 43px;
    @media only screen and (max-width: 1440px) {
      height: 38px;
    }
  }
}
