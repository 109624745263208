.card {
  width: 400px;

  .body {
    display: flex;
    color: var(--purple);
    padding: 0 !important;

    .icon {
      padding: 18px;
      font-size: 48px;
    }

    .fileContainer {
      display: flex;
      flex-direction: column;
      width: calc(100% - 84px);
      padding: 12px;

      .fileName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .checkContainer {
        margin-top: 12px;

        .input {
          position: static;
          opacity: 1;
          width: auto;
          height: auto;
          pointer-events: all;
        }

        .label {
          color: var(--purple);
          padding-left: 12px;
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center !important;
    justify-content: flex-end !important;
    padding: 6px 12px !important;
    margin: 0 !important;
    background-color: rgba(32, 16, 71, .08) !important;
  }
}
