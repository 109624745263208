.grid {
  overflow: visible !important;
}

.row:hover {
  background-color: rgba(255, 255, 255, 0.8);
}

.cell {
  color: var(--purple);

  &.address {
    flex: 0 1 400px !important;
  }
}
