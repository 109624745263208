.card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 6px 12px;
  margin: 6px 0;

  .content {
    display: flex;
    align-items: center;
    flex: 2;
    height: 100%;

    .iconContainer {
      display: flex;
      align-items: center;
      flex: 0 0 40px;
      height: calc(100% + 12px);
      padding: 0 12px;
      margin: -6px 12px -6px -12px;
      border-right: 1px solid rgba(0, 0, 0, .1);

      .icon {
        color: rgba(0, 0, 0, .4);
      }
    }

    &.light {
      .iconContainer {
        border-color: rgba(255, 255, 255, .15);
      }

      .icon {
        color: rgba(255, 255, 255, .4);
      }

      .text {
        color: white;
      }
    }
  }
}


.text {
  flex: 2;
  margin: 0;
  font-family: 'ClanPro-WideNews', sans-serif;
}
