.shipment_state {
  font-family: 'ClanPro-WideNews', sans-serif;
  font-size: 10px;
  padding: 5px 12px;
  border-radius: 50px;
}

.at_central_hub { /* at central hub */
  color: #00897B;
  background: #D7EFED;
}

.at_regional_hub { /* at regional hub */
  color: #FF5722;
  background: #FFE8D8;
}

.expected { /* expected */
  color: #A2A2A2;
  background: #EFEFEF;
}

.cancelled { /* canceled */
  color: #FF5351;
  background: #FFEAE9;
}

.delivered { /* delivered */
  color: #00D763;
  background: #CEFFE5;
}

.not_delivered { /* not delivered */
  color: #13D2FC;
  background: #E4FAFF;
}

.delivered_to_neighbour { /* delivered to neighbour */
  color: #201047;
  background: #EFE8FF;
}

.on_its_way { /* on its way */
  color: #F5A623;
  background: #FFF8D4;
}
