.no_shipment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no_shipment_text {
  font-family: ClanPro-WideNews, 'sans-serif';
  font-size: 18px;
  color: #7d858c;
}
