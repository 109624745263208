.select {
  margin-top: 0 !important;
}

.select_item {
  font-family: 'ClanPro-WideNews', sans-serif !important;
  font-size: 14px !important;
  text-align: left;
  color: #888;
}

.error_text {
  font-family: 'ClanPro-WideNews', sans-serif !important;
}
