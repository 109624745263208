.dropZone {
  height: 100%;
  transition: border-color 200ms ease-in-out;

  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    transition: background-color 200ms ease-in-out;
  }

  &.active {
    border-color: rgba(32, 16, 71, .4);

    .body {
      background-color: rgba(32, 16, 71, .05);
    }
  }

  .title {
    color: var(--purple);
    margin-bottom: 36px;
  }

  .btnIcon {
    font-size: 24px;
    margin-right: 18px;
  }
}
