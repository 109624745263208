.filter_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterText {
  font-family: inherit !important;
  @media(max-width: 1440px) {
    display: none;
  }
}

.filter_btn {
  display: table-cell !important;
  background-color: #fff !important;
  border-color: #fff !important;
  margin-right: 15px;
}

.filter_icon {
  padding-right: 3px;
  width: 18px !important;
  @media only screen and (max-width: 1440px) {
    font-size: 14px;
    display: flex;
    align-items: center;
  }
}

.date_button {
  display: flex;
  & .btn {
    height: 43px;
    margin-right: 8px;

    @media only screen and (max-width: 1440px) {
      height: 38px;
    }

    & .text {
      font-family: ClanPro-WideNews, 'sans-serif';
      @media only screen and (max-width: 1440px) {
        font-size: 10px;
      }
    }
    & .textSmall {
      display: none;
      font-family: ClanPro-WideNews, 'sans-serif';
      @media only screen and (max-width: 1440px) {
        font-size: 10px;
        display: block;
      }
    }

    & .textSmallHide {
      display: block;
      @media only screen and (max-width: 1440px) {
        display: none;
      }
    }
  }
}
