.search_container {
  display: flex;
  margin-top: 30px;
}

.search_icon {
  position: absolute;
  color: rgba(32, 16, 71, 0.75);
  height: 24px;
  width: 24px !important;
  margin-left: 36px;
  margin-top: 20px;
  z-index: 2000;
}

.backspace_clear {
  right: 0;
  color: #ff5351 !important;
  position: absolute !important;
  margin-right: 80px !important;
  margin-top: 20px !important;
  outline: none !important;

  svg {
    font-size: 24px;
    margin-right: 5px;
    vertical-align: middle;
  }
}

.search_me {
  font-family: 'ClanPro-WideNews', sans-serif !important;
  font-size: 18px !important;
  width: 100%;
  padding: 18px 100px 18px 92px;
  margin-left: 0 !important;
  margin-bottom: 24px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
}

