.container {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, .05);

  .row {
    display: flex;
    flex-wrap: nowrap;
    will-change: transform;
    transform: translateZ(0) translateX(0);

    .header {
      flex: 0 1 250px;
      color: var(--purple);
      font-size: 16px;
      font-weight: 600;
      margin-right: 10px;
      padding: 20px 0;

      &.address {
        flex: 0 1 400px !important;
      }

      &:first-child {
        margin-left: 10px;
      }
    }
  }
}
