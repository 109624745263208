.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  .childContainer {
    padding-top: 64px;
  }
}
