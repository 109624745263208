.back_drop {
  z-index: 10000 !important;
  color: #00D763;
  flex-direction: column;
}

.loading_text {
  font-size: 12px !important;
  font-family: 'ClanPro-WideNews', sans-serif;
  margin-top: 16px;
  color: #fff;
}
