.cancel {
  font-family: 'ClanPro-WideNews', sans-serif;
  font-size: 14px !important;
  color: #d1d1d1 !important;
  box-shadow: none !important;
}

.btn_create_shipment {
  font-family: 'ClanPro-WideNews', sans-serif;
  font-size: 14px !important;
  color: #fff !important;
  background-color: #00d763 !important;
  border-color: #00d763 !important;
  padding: 12px !important;
  border-radius: 5px;
}
