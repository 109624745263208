.table {
  table-layout: fixed;

  .cell {
    text-align: left;
    width: 250px;
    transition: background-color 200ms linear,
    color 200ms linear;
  }
}

