.row {
  height: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);

  &:hover {
    background-color: #f8fafc !important;
  }
}
