.header {
  vertical-align: middle !important;
  width: 250px;
  transition: background-color 200ms linear,
  color 200ms linear;

  .text {
    float: left;
    display: flex;
    align-items: center;
    width: 190px;
    height: 45px;
    font-size: 16px;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.menu {
  float: right;
}

.mappingName {
  font-size: 13px;
  margin: 0 !important;
}

