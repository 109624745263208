.buttonContainer {
  display: flex;
  align-items: center;

  .btnIcon {
    margin-right: 6px;
  }

  .continueButton {
    margin-left: 12px;
  }
}

.containerHost {
  overflow-x: auto;
}
