.form_body {
  padding: 36px !important;
  background-color: #fff;
  box-shadow: 0 2px 4px #0000001F;
  border-radius: 5px;
  height: 100%;
  color: #888;

  label, span {
    font-family: 'ClanPro-WideNews', sans-serif;
    font-size: 11px;
    text-align: left;
    margin-bottom: 6px;
  }
}

.trunkrs_api_input {
  font-family: 'ClanPro-WideNews', sans-serif !important;
  font-size: 14px !important;
  border-radius: 5px;
  border: solid 1px rgba(0, 0, 0, 0.12);
}
