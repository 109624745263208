.btn {
  font-family: 'ClanPro-WideNews', sans-serif;
  padding: 12px !important;
  border-radius: 5px !important;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  text-align: center;
  outline: none !important;

  &.green {
    background-color: #00d763 !important;
    border-color: #00d763 !important;
  }

  &.red {
    background-color: #ff5351 !important;
    border-color: #ff5351 !important;
    margin-left: 16px !important;
  }

  &.blue {
    background-color: #13d2fc !important;
    border-color: #13d2fc !important;
    margin-left: 16px !important;
  }
  @media only screen and (max-width: 1366px) {
    &.red, &.blue {
      margin-left: 8px !important;
    }
  }
}

.btn:disabled {
  cursor: not-allowed !important;
  pointer-events: auto !important;
  opacity: 0.75 !important;
}
