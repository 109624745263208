.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 220px;
  margin: 0 12px;
  justify-content: center;

  .slider {
    height: 15px;
    display: flex;
    align-items: center;
  }

  .timeWrapper {
    display: flex;
    justify-content: space-between;
  }

  .time {
    font-family: ClanPro-WideNews, 'sans-serif' !important;
    font-size: 10px;
  }
}
