.modal {
  background-color: var(--purple);

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;

    .logo {
      width: 120px;
    }
  }

  .text {
    margin-top: 24px;
    text-align: center;
    color: #fff;
  }
}
